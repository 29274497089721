<template>
  <div class="story__container">
    <!-- BANNER -->
    <banner-custom>
      <template #image>
        <div
          class="bannerbox__image d-none d-sm-block"
          :style="{ backgroundImage: 'url(' + storyPost.Img + ')' }"
        ></div>
        <div
          class="bannerbox__image d-block d-sm-none"
          :style="{ backgroundImage: 'url(' + storyPost.Img + ')' }"
        ></div>
      </template>
      <template #intro>
        <div class="intro">
          <span
            class="intro__type"
            v-html="$filters.removeHtmlTag(storyPost.HciStoryClass)"
          ></span>
          <div class="intro__inner">
            <h1 class="intro__title" v-html="$filters.removeHtmlTagP(storyPost.Title)"></h1>
            <h2 class="intro__text">{{ storyPost.SubTitle }}</h2>
          </div>
          <time class="intro__date font-enUS"
            ><span class="deco-border"></span
            >{{ $filters.dateFormat(storyPost.Date) }}</time
          >
        </div>
      </template>
      <template #mask>
        <img
          :src="storyPost.MaskImg"
          class="banner__mark img-fluid w-100 d-none d-md-block"
        />
        <img
          :src="storyPost.MMaskImg"
          class="banner__mark img-fluid w-100 d-block d-md-none"
        />
      </template>
    </banner-custom>

    <SocialShare :postTitle="storyPost.Title" />

    <article class="custom-content" v-html="storyPost.Content"></article>

    <article>
      <section class="sec pb-4" style="margin-top: -12rem">
        <img
          src="https://kinpoweb.blob.core.windows.net/hci21/frontend/images/mask-wave-secondary-light.svg"
          class="img-fluid"
          style="margin-bottom: -4px"
        />
        <div class="bg-light-secondary">
          <div class="container position-relative">
            <img
              src="https://kinpoweb.blob.core.windows.net/hci21/frontend/images/deco-heart2.png"
              class="deco deco-heart2"
            />
            <div class="row justify-content-center">
              <div class="col-lg-10">
                <Form
                  id="gogoForm"
                  @submit="handleSubmit"
                  :validation-schema="schema"
                  v-slot="{ errors }"
                  ref="myForm"
                >
                  <div class="form__card">
                    <h2 clss="form__title d-block text-center">為我們加油</h2>
                    <div class="mb-3 row">
                      <label
                        for="inputName"
                        class="col-md-3 col-form-label label-title"
                        >姓名</label
                      >
                      <div class="col-md-9">
                        <Field
                          id="inputName"
                          name="inputName"
                          type="text"
                          class="form-control"
                          :class="{ 'is-invalid': errors.inputName }"
                          placeholder="請輸入名稱"
                          v-model="comeOnFormData.Name"
                        />
                        <ErrorMessage
                          name="inputName"
                          class="text-danger ps-4"
                        />
                      </div>
                    </div>

                    <div class="mb-3 row align-items-center">
                      <label for="" class="col-md-3 col-form-label label-title"
                        >身份</label
                      >
                      <div
                        class="
                          col-md-9
                          d-flex
                          justify-content-between
                          flex-column flex-md-row
                        "
                      >
                        <div
                          class="form-check form-check-inline ms-5 ms-md-0"
                          v-for="(item, index) in vcom"
                          :key="index"
                          :value="item"
                        >
                          <Field
                            class="form-check-input option-input radio"
                            type="radio"
                            name="inputVCom"
                            :id="'radioVCom[' + index + ']'"
                            :value="item"
                            v-model="comeOnFormData.VCom"
                          />
                          <label
                            class="form-check-label"
                            :for="'radioVCom[' + index + ']'"
                            >{{ item }}</label
                          >
                        </div>
                      </div>
                      <ErrorMessage v-if="submittedShow"
                          name="inputVCom"
                          class="text-danger offset-sm-3"
                          style="padding-left: 36px;"
                        />
                    </div>

                    <!-- <div class="mb-3 row">
                      <label
                        for="inputPassword"
                        class="col-md-3 col-form-label label-title"
                        >驗證碼</label
                      >
                      <div class="col-md-9">
                        <div class="input-group position-relative">
                          <input
                            class="form-control"
                            type="text"
                            id=""
                            name="time"
                            value=""
                            placeholder="請輸入驗證碼"
                          />
                          <img
                            src="https://kinpoweb.blob.core.windows.net/hci21/frontend/images/v-code.jpg"
                            class="verify-code"
                          />
                        </div>
                      </div>
                    </div> -->

                    <div class="mb-3 row">
                      <label for="" class="col-md-3 col-form-label label-title"
                        >留言</label
                      >
                      <div class="col-md-9">
                        <Field
                          rows="4"
                          cols="50"
                          name="inputMsg"
                          class="form-control w-100"
                          placeholder="請留下100字以內的訊息"
                          as="textarea"
                          :class="{ 'is-invalid': errors.inputMsg }"
                          v-model="comeOnFormData.Msg"
                        />
                        <ErrorMessage
                          name="inputMsg"
                          class="text-danger ps-4"
                        />
                      </div>
                    </div>

                    <div class="d-flex justify-content-center pb-3">
                      <div class="col-lg-6 d-flex justify-content-center">
                        <!-- <input
                          type="submit"
                          class="btn btn-pill btn-light w-100 mx-3"
                          value="清除"
                        /> -->
                        <input
                          type="submit"
                          class="btn btn-pill btn-primary mx-3"
                          style="line-height: 0.7;"
                          value="送出"
                          @click="showRadioMsg"
                        />
                      </div>
                    </div>
                  </div>
                </Form>
                <modal-feedback v-if="isModalOpen" v-model:is-open="isModalOpen">
                  <template #text>
                    <h3>感謝你對我們的鼓勵！</h3>
                    <p>溫暖的一句話，<br>永遠是最堅強的後盾！</p>
                  </template>
                </modal-feedback>
              </div>
            </div>
          </div>
        </div>
      </section>

      <!-- SECTION -->
      <section class="related__block bg-white" style="padding-bottom: 8rem">
        <div class="container">
          <div class="row pt-3 pt-md-5 pd-2 pb-md-3">
            <div class="col-12">
              <div class="relatedbar">
                <h2 class="relatedbar__title">相關故事</h2>
                <router-link
                  :to="'/' + this.$route.params.lang + '/story'"
                  @click="clickRouterTop"
                  class="relatedbar__link d-none d-md-block"
                  >回故事列表<span class="icon-back"
                    ><span></span><span></span></span
                ></router-link>
              </div>
            </div>
          </div>
        </div>
        <div class="related__list">
          <slider-fluid :cardList="storyList" :currentRoute="currentRoute"></slider-fluid>
        </div>
        <div class="row pb-3 d-block d-md-none">
          <div class="col-12">
            <div class="relatedbar">
              <router-link
                :to="'/' + this.$route.params.lang + '/story'"
                @click="clickRouterTop"
                class="relatedbar__link"
                >回故事列表<span class="icon-back"
                  ><span></span><span></span></span
              ></router-link>
            </div>
          </div>
        </div>
      </section>
    </article>
  </div>
</template>

<script>
import axios from "axios";

import BannerCustom from "@/components/BannerCustom.vue";
import SocialShare from "@/components/SocialShare.vue";
import SliderFluid from "@/components/SliderFluid.vue";
import ModalFeedback from "@/components/ModalFeedback.vue";

import { Field, Form, ErrorMessage, configure } from "vee-validate";
import * as Yup from "yup";

configure({
  validateOnBlur: true, // controls if `blur` events should trigger validation with `handleChange` handler
  validateOnChange: true, // controls if `change` events should trigger validation with `handleChange` handler
  validateOnInput: false, // controls if `input` events should trigger validation with `handleChange` handler
  validateOnModelUpdate: true, // controls if `update:modelValue` events should trigger validation with `handleChange` handler
});

export default {
  components: {
    SliderFluid,
    BannerCustom,
    SocialShare,
    Field,
    Form,
    ErrorMessage,
    ModalFeedback
  },
  data() {
    return {
      seoData: null,
      sTitle: null,
      sDescription: null,
      sKeywords: null,
      sSiteName: null,
      sImage: null,
      sType: null,
      showFeedback: false,
      submittedShow: false,
      isModalOpen:false,
      currentRoute: "story",
      currentURL: 'https://www.hci.org.tw/zh-TW/story/'+this.$route.params.id,
      pagesBanner: [],
      storyPost: [],
      storyList: [],
      vcom: [],
      comeOnFormData: [
        {
          Name: "",
          VCom: "",
          Msg: "",
          Story: "",
        },
      ],
      schema: Yup.object().shape({
        inputName: Yup.string().required("姓名為必填欄位").label("姓名"),
        inputVCom: Yup.string().required("身份為必填欄位").oneOf(["金仁寶集團員工" , "一般社會愛心人士"], '身份為必填欄位').label("身份"),
        inputMsg: Yup.string().max(100, "限100字以內").required("留言為必填欄位").label("留言"),

      }),

    };
  },
  watch: {
    '$route' () { 
      this.storyP();
      this.storyL();
    }
  },
  mounted() {
    this.storyP();
    this.storyL();

    var seoFD = new FormData();
    seoFD.append("mode", "raw_sct");
    seoFD.append("data", '{"class":"HciSEO","lang":"zh-TW"}');
    axios({
      method: "post",
      url: "https://nkg-web-api.japaneast.cloudapp.azure.com/Form/",
      data: seoFD,
      headers: { "Content-Type": "multipart/form-data" },
    }).then((res) => {
      const currentUrlName = (this.currentRoute+'/'+this.$route.params.id).toLowerCase();
      console.log("currentUrlName, this.$route====>", currentUrlName, this.$route);
      console.log("seoData====>", res.data.data);
      this.seoData = res.data.data.filter(item => (item.SetPage == currentUrlName));
      this.sTitle = this.seoData[0].title;
      this.sDescription= this.seoData[0].description;
      this.sKeywords = this.seoData[0].keywords;
      this.sSiteName = this.seoData[0].site_name;
      this.sImage = this.seoData[0].image;
      this.sType = this.seoData[0].type;

      console.log("this.seoData.setPage ====>", this.seoData[0].SetPage);

      const siteTitle = document.querySelector("title");
      siteTitle.innerText = this.sTitle;

      const seoDescriptionD = document.querySelector("meta[name='description']");
      seoDescriptionD.setAttribute('content', this.sDescription);

      const seoTitle = document.querySelector("meta[property='og:title']");
      seoTitle.setAttribute('content', this.sTitle);

      const seoDescription = document.querySelector("meta[property='og:description']");
      seoDescription.setAttribute('content', this.sDescription);

      const seoSiteName = document.querySelector("meta[property='og:site_name']");
      seoSiteName.setAttribute('content', this.sSiteName);

      const seoImg = document.querySelector("meta[property='og:image']");
      seoImg.setAttribute('content', this.sImage);

      const seoType = document.querySelector("meta[property='og:type']");
      seoType.setAttribute('content', this.sType);

      const seoKeywords = document.querySelector("meta[name='keywords']");
      seoKeywords.setAttribute('content', this.sKeywords);

      const seoURL = document.querySelector("meta[property='og:url']");
      seoURL.setAttribute('content', this.currentURL);
    });

    //篩選特定頁面資料
    var FD = new FormData();
    FD.append("mode", "raw_sct");
    FD.append(
      "data",
      '{"class":"HciStory","lang":"zh-TW","key":"SeoUrl","val":"' +
        this.$route.params.id +
        '"}'
    );

    axios({
      method: "post",
      url: "https://nkg-web-api.japaneast.cloudapp.azure.com/Form/",
      data: FD,
      headers: { "Content-Type": "multipart/form-data" },
    }).then((res) => {
      this.storyPost = res.data.data[0];
      console.log("this.storyPost", this.storyPost);

      //v-html run script
      const scriptUrls = ["https://kinpoweb.blob.core.windows.net/hci21/frontend/script/jquery.min.js",
        "https://kinpoweb.blob.core.windows.net/hci21/frontend/script/splide@4.0.7.min.js",
        "https://kinpoweb.blob.core.windows.net/hci21/frontend/script/custom.js"];
      for (const url of scriptUrls) {
        const scriptEl = document.createElement('script');
        scriptEl.src = url;
        document.body.appendChild(scriptEl);
      }
    });

    var FD3 = new FormData();
    FD3.append("mode", "raw_sct");
    FD3.append("data", '{"class":"ComeOn","lang":"zh-TW"}');
    axios({
      method: "post",
      url: "https://nkg-web-api.japaneast.cloudapp.azure.com/Form/",
      data: FD3,
      headers: { "Content-Type": "multipart/form-data" },
    }).then((res) => {
      this.vcom = res.data.VCom;
      console.log("this.vcom", this.vcom, res.data.VCom);
    });
  },
  methods: {
    showRadioMsg(){
      this.submittedShow = true;
    },
    storyP() {
      var storyFD1 = new FormData();
      storyFD1.append("mode", "raw_sct");
      storyFD1.append(
        "data",
        '{"class":"HciStory","lang":"zh-TW","key":"SeoUrl","val":"' +
          this.$route.params.id +
          '"}'
      );
      axios({
        method: "post",
        url: "https://nkg-web-api.japaneast.cloudapp.azure.com/Form/",
        data: storyFD1,
        headers: { "Content-Type": "multipart/form-data" },
      }).then((res) => {
        this.storyPost = res.data.data[0];

        this.comeOnFormData.Story = "https://www.hci.org.tw/zh-TW/story/" + this.$route.params.id;
        console.log("this.comeOnFormData.Story===>", this.comeOnFormData.Story)
        console.log("this.$route.params.id===>", this.$route.params.id)
      });
    },
    storyL() {
      var storyFD2 = new FormData();
      storyFD2.append("mode", "raw_sct");
      storyFD2.append("data", '{"class":"HciStory","lang":"zh-TW","sw":"1"}');
      axios({
        method: "post",
        url: "https://nkg-web-api.japaneast.cloudapp.azure.com/Form/",
        data: storyFD2,
        headers: { "Content-Type": "multipart/form-data" },
      }).then((res) => {
        this.storyList = res.data.data.filter(item => (item.SeoUrl!==this.$route.params.id));
        console.log(this.$route.params.id);
        console.log("storyList", this.storyList);
      });
    },
    clickRouterTop(){
      //chrome
      document.body.scrollTop = 0
      //firefox
      document.documentElement.scrollTop = 0
      //safari
      window.pageYOffset = 0
    },
    handleReset() {
      this.$refs.myForm.resetForm();
      this.comeOnFormData = [];
    },
    handleSubmit() {
      var formData = new FormData();

      formData.append("Name", this.comeOnFormData.Name);
      formData.append("VCom", this.comeOnFormData.VCom);
      formData.append("Msg", this.comeOnFormData.Msg);
      formData.append("Story", this.comeOnFormData.Story);

      formData.append("mode", "add");

      formData.append(
        "data",
        "{" +
          '"class":"ComeOn",' +
          '"lang":"zh-TW",' +
          '"Name":"' +
          this.comeOnFormData.Name +
          '",' +
          '"VCom":"' +
          this.comeOnFormData.VCom +
          '",' +
          '"Msg":"' +
          this.comeOnFormData.Msg +
          '",' +
          '"Story":"' +
          this.comeOnFormData.Story +
          '",' +
          '"RandCode":"' +
          Math.random() +
          '"}'
      );

      axios({
        method: "post",
        url: "https://nkg-web-api.japaneast.cloudapp.azure.com/Form/",
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      }).then((res) => {
        console.log("[comeOnFormData]" + res);

        this.isModalOpen = true;
        this.handleReset();

        console.log("送出去的this.comeOnFormData.VCom==>",this.comeOnFormData.VCom)

        console.log("姓名", this.comeOnFormData.Name);
        console.log("身份", this.comeOnFormData.VCom);
        console.log("留言", this.comeOnFormData.Msg);
        console.log("Story", this.comeOnFormData.Story);
      });
    }
  }
}
</script>
<style lang="scss">
.story__container .intro__text {
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  margin-bottom: 1rem;
}

.custom-content section:last-child {
  padding-bottom: 10rem;
}

.form__card h2 {
  font-size: calc(1.3rem + 0.6vw);
  font-weight: 900;
  color: #fff;
  display: block;
  text-align: center;
  margin-bottom: 3rem;
  @media (max-width: 576px) {
    margin-bottom: 1rem;
  }
}

.deco-heart2 {
  width: 170px;
  top: -12%;
  left: 10%;
  @media (max-width: 1080px) {
    top: -16%;
    left: 2%;
  }
  @media (max-width: 768px) {
    width: 150px;
    top: -8%;
    left: 0%;
  }
  @media (max-width: 576px) {
    width: 80px;
    top: -7%;
    left: 40%;
  }
}
</style>
